
/**
 * Spiral coordinate generator. Starts at 0 and extends in a spiral until the specified size is reached
 */
export function* spiral(sizeX, sizeY) {

    // Adapted from: https://stackoverflow.com/a/31864777/1008736
    let x = 0
    let y = 0
    let reuseOutput = { x: 0, y: 0 }
    let size = Math.max(sizeX, sizeY) ** 2
    for (let i = 0 ; i < size ; ++i) {

        // Check if within range
        let xp = x + sizeX / 2;
        let yp = y + sizeY / 2;
        if (xp >= 0 && xp < sizeX && yp >= 0 && yp < sizeY) {

            // Yield the next value
            // Note: We're using a reusable object for the output to
            // save on object creations
            reuseOutput.x = x
            reuseOutput.y = y
            yield reuseOutput

        }

        // Increase the spiral position
        if (Math.abs(x) <= Math.abs(y) && (x != y || x >= 0))
            x += ((y >= 0) ? 1 : -1)
        else
            y += ((x >= 0) ? -1 : 1)

    }

}