import React from 'react';
import { createRoot } from 'react-dom/client'
import { UAParser } from 'ua-parser-js';
import App3D from './App3D'
import { HexOutline } from './CommonComponents';
import Database from './Database';
import { SharedComponents } from './SharedComponents';
import algoliasearch from "algoliasearch";

/** Main app */

class App extends React.PureComponent {

    /** Initial state */
    state = {
        showZoomToast: true,
        isHoveringY: false,
        isHoveringN: false,
        isLoadingSearch: false,
        search: null,
        users: [],
        foundUsers: []
    }

    /** Current campaign ID */
    campaignID = new URLSearchParams(location.search).get("campaign")
    
    /** Current token */
    token = new URLSearchParams(location.search).get("token") || null

    /** On app startup */
    async componentDidMount() {
        // Store this instance
        SharedComponents.app = this

        // Detect high-powered devices by detecting OS version. We assume the device is high powered if it's running
        // the latest iOS and Android versions
        let ua = UAParser()

        // Desktop OSes can be considered high-powered
        if (ua.os?.name == 'Windows' || ua.os?.name == 'Mac OS' || ua.os?.name == 'Linux') 
            SharedComponents.isHighPowered = true

        // iOS 15+ can be considered high-powered ... NOPE, all iOS devices are shit, apparently
        if ((ua.os?.name == 'iOS' || ua.os?.name == 'iPad OS') && parseFloat(ua.os?.version) >= 15)
            SharedComponents.isHighPowered = true

        // Android 12+ can be considered high-powered
        if (ua.os?.name == 'Android' && parseFloat(ua.os?.version) >= 12)
            SharedComponents.isHighPowered = true

        // Check for iOS issues
        SharedComponents.isIOS = ua.os?.name == 'iOS' || ua.os?.name == 'iPad OS'

        // Attempt to load app
        try {

            // Fetch database content
            await Database.shared.load()

            // Start the Babylon component
            // window.setLoader(0.75)
            await App3D.shared.start()

            // Update users
            this.setState({users: Database.shared.allUsers})
            // this.searchUsers("")

            // Set the discover users
            this.discoverUsers = Database.shared.discoverableUsers || []

            // Hide loader
            window.setLoader(1)
            window.removeLoader()

        } catch (err) {

            // Load failed
            console.error(err)
            this.setState({ error: err })
            setTimeout(e => window.removeLoader(), 100)

        }

        // Force update again
        this.forceUpdate()

    }

    /** Called by App3D, hides the zoom toast */
    hideZoomToast() {
        if (this.state.showZoomToast) this.setState({ showZoomToast: false })
    }

    /** Load a user */
    async searchUsers(search) {
        const client = algoliasearch('1WB4E7CA90','57ac802028147530cdbd20d9e1510a49');
        const userIndex = client.initIndex("users");
        let users = []
        let dbDiscoverUsers = Database.shared.discoverableUsers
   
        // Attempt marketplace search
        let results = null
        let campaignID = "campaignIds:"+this.campaignID
        try {
            results = await userIndex.search(search, {
                restrictSearchableAttributes: [
                    'name'
                  ],
                facetFilters: [
                [ 
                    campaignID
                ],
                [
                 "addedToBall:true"
                ]
                ],
                hitsPerPage: 100
            })
        
        } catch (err) {
            console.warn('[Plugins] Unable to search the marketplace.', err)
        }
        
        if (results) {
            let userObject = {}
            for (let user of results.hits) {
                userObject = {}
                userObject.photoLowRes = user.pepsiBallImage || user.picture
                userObject.id = user.objectID
                userObject.name = user.name

                users.push(userObject)
            }
        }      
        
        this.discoverUsers = dbDiscoverUsers
        this.allUsers = users
        this.users = users
        if(this.users && search.length >= 2){
            // this.users = this.users.filter(e => e.name?.toLowerCase().includes(search.toLowerCase()))
            return this.users
        }else{
            return
        }
    }


    /** Display user */
    async displayUser(user, color) {

        // Catch errors
        try {

            // Show user info
            console.debug(`[UI] Displaying user ${user.id}`)
            this.setState({
                displayUser: user.id,
                displayUserName: 'Loading...',
                displayUserPhoto: user.photoLowRes,
                displayUserHighResPhoto: null,
                displayUserHighResPhotoLoaded: false,
                displayUserLocation: '',
                displayUserTeam: '',
                displayUserColor: color,
                displayUserTime: Date.now(),
                showReportPopup: false,
                showReportWindow: false,
                showReportAlert: false
            })

            // Load user info from Vatom SaaS platform
            let json = await Promise.all([
                fetch(`https://users.vatom.com/${user.id}`).then(r => r.json()),
                fetch(`https://users.vatom.com/${user.id}/gsndxqUVp1/${this.campaignID}`).then(r => r.json()),
            ])
            
            json = json.reduce((acc, curr) => {
                return {
                    ...acc,
                    ...curr
                }
            }, {})
            
            // Stop if another user was loaded in the interim
            if (this.state.displayUser != user.id)
                return

            // Update user info
            this.setState({
                displayUserName: json.name || 'No Name',
                displayUserHighResPhoto: json.picture,
                displayUserLocation: json.pepsi_address?.city || json.pepsi_address?.region || json.pepsi_address?.country,
                displayUserTeam: json.pepsi_team
            })

        } catch (err) {

            // Unable to load
            console.warn(err)
            alert('Unable to load user: ' + err.message)
            this.setState({ displayUser: false })

        }

    }

    /** Called when the popup background is clicked */
    onPopupBackgroundClicked(e) {

        // Stop if wrong target. This prevents clicking inside the popup from also closing it.
        if ((e.target.id != 'popup-bg' && e.target.id != 'alert-report' && e.target.id != 'popup-report') || e.target.id == 'report-button')
            return

        // Stop if it only just opened. This prevents the click action from triggering immediately by mistake.
        if (Date.now() - this.state.displayUserTime < 500)
            return

        // Close the popup
        this.setState({ displayUser: false, displayDiscover: false, showReportAlert: false, showReportPopup: false })

    }

    /** Called when the user presses the Flag button */
    flagUser() {

        // Confirm
        let c = confirm("Are you sure you want to flag this user for inappropriate content?")
        if (!c)
            return

    }

    async reportImage(reason) {

        if (this.reporting) return
        
        this.reporting = true
        let body = {}
        body.imageUrl = this.state.displayUserHighResPhoto
        body.flaggedCategory = reason
        body.userId = this.state.displayUser

        if (!this.token) {
            this.setState({showReportAlert: true, showAlertFailed: true})
            console.warn("Can't report the image. No token parameter found")
            this.reporting = false
            return
        }

        try {
            // Send POST request report the image
            let response = await fetch(`https://moderation.api.vatominc.com/flag`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.token 
                },
                body: JSON.stringify(body)
            })

            // Parse response
            let json = await response.json()

            // If error
            if (!response.ok || json.error) {
                const errorMessage = json.error_description || json.error || (response.status + ' ' + response.statusText)
                this.setState({showReportAlert: true})
                this.reporting = false
                throw new Error(`Failed to receive response from Allowl API. Error: ${errorMessage}`)
            } else {
                this.reporting = false
                this.setState({showReportPopup: false, showAlertFailed: false})
            }
        }
        catch(err) {
            console.error(err)
            this.reporting = false
            this.setState({showReportPopup: false, showAlertFailed: false})
        }
        
        return
    }

    /** Render */
    render() {

        // If there's an error, show it
        if (this.state.error) return this.renderError()
        else return this.renderApp()
        
    }

    defineResults = (results) => {
        this.setState({ foundUsers: results, isLoadingSearch: false})
    }

    /** Render error screen */
    renderError = () => <>

        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: 'white', zIndex: 5 }}>
            <div style={{ width: 64, height: 64, display: 'block', backgroundImage: `url(${require('./warning.svg')})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', marginBottom: 40 }}></div>
            <div style={{ fontSize: 13, color: '#888', textAlign: 'center', marginLeft: 40, marginRight: 40 }}>
                {this.state.error?.message || 'Unable to load app'}
            </div>
        </div>

    </>

    /** Render app */
    renderApp = () => <>

        {/* <div style={{position: 'absolute', top: 0, left: 0, zIndex: 5 }}>(2D elements go here)</div> */}

        {/* Buttons */}
        <div style={{ position: 'absolute', top: 20, left: 20, zIndex: 2 }}>

            {/* Home button */}
            <div style={{ display: 'inline-block', width: 64, height: 64, borderRadius: 32, cursor: 'pointer', backgroundColor: 'rgba(50, 50, 50, 0.75)', backgroundImage: `url(${require('./home.svg')})`, backgroundSize: '24px 24px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', marginRight: 16 }} 
                onClick={e => SharedComponents.app3D.goToCurrentUser()}/>

            {/* Discover button, only shown if discoverable users exist in this collection */}
            
                <div style={{ display: 'inline-block', width: 64, height: 64, borderRadius: 32, cursor: 'pointer', backgroundColor: 'rgba(50, 50, 50, 0.75)', backgroundImage: `url(${require('./search.svg')})`, backgroundSize: '24px 24px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', marginRight: 16 }} 
                    onClick={e => {this.setState({ displayUser: false, displayDiscover: true }), this.onSearchChanged("")}}/>
            

        </div>

        {/* Render the pinch info helper */}
        <div style={{ position: 'absolute', bottom: 40, left: 0, width: '100%', pointerEvents: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 2, opacity: this.state.showZoomToast ? 1 : 0, transition: 'opacity 1s' }}>
            <div style={{ backgroundColor: 'rgba(50, 50, 50, 0.75)', borderRadius: 32, fontSize: 15, margin: '0px 20px 0px 20px', color: '#FFF', padding: '16px 32px' }}>
                <b>Pinch</b> or <b>scroll</b> to zoom in
            </div>
        </div>

        {/* Render the user info window */}
        <div id='popup-bg' style={{ pointerEvents: this.state.displayUser ? 'auto' : 'none', position: 'absolute', top: this.state.displayUser ? 0 : 100, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 3, opacity: this.state.displayUser ? 1 : 0, transition: 'opacity 0.3s, top 0.3s' }} onClick={e => this.onPopupBackgroundClicked(e)}>
            <div style={{ position: 'relative', width: 300, minHeight: 100, backgroundColor: 'rgba(50, 50, 50, 0.9)', borderRadius: 32, color: '#AAA', fontSize: 11 }}>

                {/* User image mask */}
                <HexOutline hexColor={this.state.displayUserColor} style={{ width: 180, height: 180, position: 'absolute', top: -90, left: 'calc(50% - 90px)' }}>
                    
                    {/* Low res image */}
                    <img src={this.state.displayUserPhoto || require('./avatar.png')} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', opacity: this.state.displayUserHighResPhoto && this.state.displayUserHighResPhotoLoaded ? 0 : 1 }} />

                    {/* High res photo */}
                    <img src={this.state.displayUserHighResPhoto || require('./avatar.png')} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', opacity: this.state.displayUserHighResPhoto && this.state.displayUserHighResPhotoLoaded ? 1 : 0 }} onLoad={e => this.setState({ displayUserHighResPhotoLoaded: true })} />

                </HexOutline>

                {/* Report button */}
                <div id='report-button' style={{ position: 'absolute', top: 5, right: 35, width: 18, height: 18, backgroundSize: '8px 8px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${require('./moredots.svg')})`, cursor: 'pointer' }} onClick={e => this.setState({ showReportPopup: true })} />

                {/* Close button */}
                <div style={{ position: 'absolute', top: 5, right: 15, width: 18, height: 18, backgroundSize: '8px 8px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${require('./close.svg')})`, cursor: 'pointer' }} onClick={e => this.setState({ displayUser: false })} />

                {/* Flag button */}
                {/* <div style={{ position: 'absolute', top: 0, left: 0, width: 44, height: 44, backgroundSize: '12px 12px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${require('./red-flag.svg')})`, cursor: 'pointer' }} onClick={e => this.flagUser()} /> */}

                {/* User's name */}
                <div style={{ margin: '100px 20px 20px 20px', textAlign: 'center', fontSize: 17, color: 'white', fontWeight: 'bold' }}>{this.state.displayUserName || '-'}</div>

                {/* User extra info */}
                <table style={{ border: 'none', background: 'none', borderCollapse: 'collapse', margin: 'auto', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '50%', fontWeight: 'bold', color: '#DDD', textAlign: 'right', paddingRight: 5, paddingBottom: 5, verticalAlign: 'top' }}>Location:</td>
                            <td style={{ width: '50%', paddingLeft: 5, paddingBottom: 5, verticalAlign: 'top' }}>{this.state.displayUserLocation || '-'}</td>
                        </tr>
                        <tr>
                            <td style={{ width: '50%', fontWeight: 'bold', color: '#DDD', textAlign: 'right', paddingRight: 5, paddingBottom: 20, verticalAlign: 'top' }}>Team:</td>
                            <td style={{ width: '50%', paddingLeft: 5, paddingBottom: 20, verticalAlign: 'top' }}>{this.state.displayUserTeam || '-'}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

        {/* Render the discover window */}
        <div id='popup-disc' style={{ pointerEvents: this.state.displayDiscover ? 'auto' : 'none', position: 'absolute', top: this.state.displayDiscover ? 0 : 100, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 3, opacity: this.state.displayDiscover ? 1 : 0, transition: 'opacity 0.3s, top 0.3s' }} onClick={e => this.onPopupBackgroundClicked(e)}>
            <div style={{ position: 'relative', width: 360, maxHeight: 'calc(100% - 80px)', height: 500, backgroundColor: 'rgba(50, 50, 50, 0.9)', borderRadius: 32, color: '#AAA', fontSize: 11, overflow: 'hidden' }}>

                {/* Scrollable items */}
                <div className='hide-scrollbar' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflowX: 'hidden', overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}>

                    {/* Search Bar */}
                    <SearchField style={{marginTop: 40}} placeholder='Search' value={this.state.search} onChange={e => this.onSearchChanged(e.target.value)} onClear={e => this.onSearchChanged(false)} />

                    {/* Title */}
                    {/* <div style={{ display: 'block', margin: 22, fontSize: 17, fontWeight: 'bold', color: 'white', textAlign: 'center' }}>Discover People</div> */}

                    {/* Each item */}
                    {this.state?.search?.length >= 2 && this.state.isLoadingSearch === false ?
                    <div style={{width: '100%'}}>
                        {this.state.foundUsers?.map((user, idx) => {
                            // Calculate hex cell position
                            idx = idx + 1
                            let x = idx % 2
                            let y = Math.floor(idx)

                            // Adjust for hex layout
                            let pixelX = x + 16 + (y % 2 == 1 ? 160 : 0)// (128+16) + (y % 2 == 1 ? (128+16)/2 : 0) + 16
                            let pixelY = y * 100 //+ 124 
         
                            this.lastX = pixelX
                            this.lastY = pixelY
                            // Render UI
                            return <div key={idx+'a'} style={{ position: 'absolute', left: pixelX, top: pixelY }}>
                                <HexOutline key={idx} style={{ position: 'absolute', width: 160, height: 160, cursor: 'pointer' }} hexColor='#ffbe4d' hexThickness={10} onClick={e => this.selectedDiscoverableUser(user)}>
                                    <img src={user.photoLowRes || require('./avatar.png')} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }} />
                                </HexOutline>  
                            <div style={{height: 175, width: 160, display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                                <div style={{width: '120px', textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow:'ellipsis', bottom: 0,position: 'absolute', color: 'white'}}>{user.name}</div>
                            </div>
                            </div>  

                        })} 
                    </div> :  this.state?.search?.length > 2 && this.state.isLoadingSearch === true ? <div style={{display:'flex', widht:'100%', minHeight: '300px', justifyContent: 'center', alignItems: 'center'}}><img style={{width:64, height:64}} src={require('./loading.gif')} /></div> : null}

                    { this.state?.search?.length > 2 && this.users?.length > 0? <hr style={{ width: 314, height: 0, border: '1px solid #F9B93E', position: 'absolute', left: 20, top: this.lastY ? this.lastY + 200 : 0}}></hr> : null}

                    {/* Each item */}
                    <div style={{width: '100%', paddingBottom: 40}}>
                        {this.discoverUsers?.map((user, idx) => {
                            // Calculate hex cell position
                            idx = idx + 1
                            let x = idx % 2
                            let y = Math.floor(idx)
                            let lastY = this.lastY && this.state?.search?.length > 0 ? this.lastY + 120 : 0

                            // Adjust for hex layout
                            let pixelX = x + 16 + (y % 2 == 1 ? 160 : 0)// (128+16) + (y % 2 == 1 ? (128+16)/2 : 0) + 16
                            let pixelY = y * 100 + lastY //+ 124
                            
                            // Render UI
                            return <div key={idx+'a'} style={{ position: 'absolute', left: pixelX, top: pixelY }}>
                                <HexOutline key={idx} style={{ position: 'absolute', width: 160, height: 160, cursor: 'pointer' }} hexColor='#ffbe4d' hexThickness={10}  onClick={e => this.selectedDiscoverableUser(user)}>
                                    <img src={user.photoLowRes} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }} />
                                </HexOutline>  
                            <div style={{height: 175, width: 160, display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                                <div style={{bottom: 0,position: 'absolute', color: 'white'}}>{user.name}</div>
                            </div>
                            </div>  

                        })}
                    </div>

                </div>

                {/* Close button */}
                <div style={{ position: 'absolute', top: 0, right: 0, width: 44, height: 44, backgroundSize: '8px 8px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${require('./close.svg')})`, cursor: 'pointer' }} onClick={e => this.setState({ displayDiscover: false })} />

            </div>
        </div>

        {/* Render the report popup */}
        <div id='popup-report' style={{ pointerEvents: this.state.showReportPopup ? 'auto' : 'none', position: 'absolute', top: this.state.showReportPopup ? 0 : 100, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 3, opacity: this.state.showReportPopup ? 1 : 0, transition: 'opacity 0.3s, top 0.3s'}} onClick={e => this.onPopupBackgroundClicked(e)}>
            <div style={{ position: 'relative', width: 300, minHeight: 100, backgroundColor: 'white', borderRadius: 10, color: '#AAA', fontSize: 11, display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <img src={require('./warningblack.svg')} style={{ top: 0, left: 0, width: 40, height: 40, padding: 10 }} />
                <div style={{ padding: 10}}>
                    <div style={{fontWeight: 700, fontSize: 18, color: 'black', paddingTop: 10 }}>
                        Report Post
                    </div>
                    <div style={{fontWeight: 700, width: '100%', padding: '2px 0px', fontSize: 12, color: 'black'}}>
                        Do you want to report this post?
                    </div>
                </div>
                <div style={{ justifyContent: 'center', display: 'flex', width: '100%', paddingTop: 10, paddingBottom: 20}}>
                    <label style={{ fontSize: 13, color: 'black', marginRight: 20}}>Reason: </label>
                    <select name='Reason' id='reason'>
                        <option value="NUDITY">Nudity</option>
                        <option value="VIOLENCE">Violence</option>
                        <option value="VISUALLY_DISTURBING">Disturbing</option>
                        <option value="RUDE_GESTURES">Rude Gestures</option>
                        <option value="DRUGS">Drugs</option>
                        <option value="TOBACCO">Tobacco</option>
                        <option value="ALCOHOL">Alcohol</option>
                        <option value="GAMBLING">Gambling</option>
                        <option value="HATE_SYMBOLS">Hate Speach/Symbols</option>
                        <option value="UNCATEGORIZED">Something Else</option>
                    </select>
                </div>
                <button onMouseOver={e => this.setState({isHoveringY: true})} onMouseOut={e => this.setState({isHoveringY: false})} style={{display: 'block', margin: '0 auto', width: '50%', height: 48, cursor: 'pointer', backgroundColor: this.state.isHoveringY ? '#DADADA' : 'white', color: 'black', borderBottomLeftRadius: 10, borderLeftColor: 'white', borderBottomColor: 'white', borderTopColor: '#DADADA', borderRightColor: '#DADADA', borderStyle: 'solid'}} onClick={e => {this.reportImage(document.getElementById('reason').value)}}  >
                    Report
                </button>
                <button onMouseOver={e => this.setState({isHoveringN: true})} onMouseOut={e => this.setState({isHoveringN: false})}style={{display: 'block', margin: '0 auto', width: '50%', height: 48, cursor: 'pointer', backgroundColor: this.state.isHoveringN ? '#DADADA' : 'white', color: 'black', borderBottomRightRadius: 10, borderLeftColor: '#DADADA', borderBottomColor: 'white', borderTopColor: '#DADADA', borderRightColor: 'white', borderStyle: 'solid', borderLeftWidth: 0}} onClick={e => this.setState({showReportPopup: false})} >
                    Cancel
                </button>
            </div>
        </div>

        {/* Render the report window */}
        <div id='window-report' style={{ pointerEvents: this.state.showReportWindow ? 'auto' : 'none', position: 'absolute', top: this.state.showReportWindow ? 0 : 100, left: 0, width: window.innerWidth, height: window.innerHeight, zIndex: 4, opacity: this.state.showReportWindow ? 0.9 : 0, transition: 'opacity 0.3s, top 0.3s', backgroundColor: 'black'}}>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <div style={{ color: 'white', fontSize: 26, fontWeight: 700, paddingBottom: 10, paddingTop: 20}}>Report</div>
                    <img src={require('./close.svg')} style={{ top: 0, right: 20, width: 25, height: 25, paddingTop: 22, cursor: 'pointer', position: 'absolute' }} onClick={e => this.setState({ showReportWindow: false })} />
                </div>
                <hr style={{ border: 'none', height: 2, color: '#6D6D6D', backgroundColor: '#6D6D6D', width: '100%' }}/>

                <div style={{ color: 'white', fontSize: 18, fontWeight: 700, padding: '20px 40px ', width: '100%'}}>Please select a problem</div>
                <div style={{ display: 'flex', width: '100%'}}>
                    <div style={{ color: 'white', fontSize: 16, fontWeight: 300, padding: '15px 0px 15px 40px', width: '80%'}}>Nudity</div>
                    <img src={require('./arrow.svg')} style={{ top: 0, cursor: 'pointer'}} onClick={e => {} } />
                </div>
                <div style={{ display: 'flex', width: '100%'}}>
                    <div style={{ color: 'white', fontSize: 16, fontWeight: 300, padding: '15px 0px 15px 40px', width: '80%'}}>Spam</div>
                    <img src={require('./arrow.svg')} style={{ top: 0, cursor: 'pointer'}} onClick={e => {} } />
                </div>
                <div style={{ display: 'flex', width: '100%'}}>
                    <div style={{ color: 'white', fontSize: 16, fontWeight: 300, padding: '15px 0px 15px 40px', width: '80%'}}>Inappropriate Content</div>
                    <img src={require('./arrow.svg')} style={{ top: 0, cursor: 'pointer'}} onClick={e => {} } />
                </div>
                <div style={{ display: 'flex', width: '100%'}}>
                    <div style={{ color: 'white', fontSize: 16, fontWeight: 300, padding: '15px 0px 15px 40px', width: '80%'}}>Violence</div>
                    <img src={require('./arrow.svg')} style={{ top: 0, cursor: 'pointer'}} onClick={e => {} } />
                </div>
                <div style={{ display: 'flex', width: '100%'}}>
                    <div style={{ color: 'white', fontSize: 16, fontWeight: 300, padding: '15px 0px 15px 40px', width: '80%'}}>Something Else</div>
                    <img src={require('./arrow.svg')} style={{ top: 0, cursor: 'pointer'}} onClick={e => {} } />
                </div>
            </div>
        </div>

        {/* Render the report alert */}
        <div id='alert-report' style={{ pointerEvents: this.state.showReportAlert ? 'auto' : 'none', position: 'absolute', top: this.state.showReportAlert ? 0 : 100, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 3, opacity: this.state.showReportAlert ? 1 : 0, transition: 'opacity 0.3s, top 0.3s'}} onClick={e=> this.onPopupBackgroundClicked(e)} >
            <div style={{ position: 'relative', width: 280, minHeight: 100, backgroundColor: 'white', borderRadius: 10, color: '#AAA', fontSize: 11, display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <img src={require('./checkmarkcircle.svg')} style={{ top: 0, left: 0, width: 25, height: 25, padding: 10, paddingLeft: 20 }} />
                <div>
                    <div style={{fontWeight: 500, fontSize: 13, color: 'black' }}>
                       {this.state.showAlertFailed ? "No token parameter found" : "Thanks for reporting this post"}
                    </div>
                    <img src={require('./close.svg')} style={{ top: 10, right: 10, width: 12, height: 12, cursor: 'pointer', position: 'absolute' }} onClick={e => this.setState({ showReportAlert: false, showReportPopup: false })} />
                    </div>
            </div>
        </div>
    </>

    /** Called when the suer selects a discoverable user */
    selectedDiscoverableUser(user) {
        // Hide overlay
        this.setState({ displayDiscover: false })

        // Wait a bit, then show the user's panel
        setTimeout(e => this.displayUser(user, '#ffbe4d'), 250)

        // Show them on the 3D map
        SharedComponents.app3D.showUser(user)

    }

    /** @private Called when the contents of the search field changes. */
    async onSearchChanged(newSearch) {
        // Increment counter
        this.searchCounter = (this.searchCounter || 0) + 1
        let counter = this.searchCounter

        // Set text field state
        this.setState({
            search: newSearch,
            searchResults: [],
            searchInProgress: true,
            searchError: null,
            foundUsers: [],
            isLoadingSearch: true
        })
        clearTimeout(this.searchTimer)
        // Perform search soon, wait for user to stop typing
        this.searchTimer = setTimeout(async() => {
        // Stop if another search started while we were still getting results
        if (counter != this.searchCounter)
            return
        
        if(newSearch.length >= 2){
        this.defineResults(await this.searchUsers(newSearch))
        }else{
            this.setState({ foundUsers: [], isLoadingSearch: false }) 
        }
    }, 2000)
    }

}

// Render app
let appContainer = document.createElement('div')
appContainer.id = 'react-app'
document.body.appendChild(appContainer)
let root = createRoot(appContainer)
root.render(<App />)

// Prevent weird folding on iPhones
document.addEventListener('touchstart', function(e) { 
    e.preventDefault()
})
document.addEventListener('touchmove', function(e) { 
    e.preventDefault()
})

export const SearchField = props => {

    // Render UI
    return <div style={Object.assign({ display: 'flex', flexDirection: 'row', flexGrow: 0, flexShrink: 0, height: 38, margin: 10, borderRadius: 6, backgroundColor: 'rgba(255, 255, 255, 0.1)' }, props.style)}>

        {/* Search icon */}
        <div style={{ flex: '0 0 auto', width: 48, height: '100%', backgroundImage: `url(${require('./search.svg')})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />

        {/* Input field container */}
        <div style={{ flex: '1 1 auto', position: 'relative', width: '100%' }}>

            {/* Placeholder text */}
            <div style={{ display: props.value ? 'none' : 'flex', alignItems: 'center', position: 'absolute', top: 0, left: 0, height: '100%', margin: 0, padding: 0, color: '#7B8082', fontSize: props.placeholderFontSize || props.fontSize || 16, fontWeight: 400 }}>
                { props.placeholder || 'Search...' }
            </div>

            {/* Input field */}
            <input style={{ position: 'absolute', top: 0, left: 0, width: 'calc(100% - 5px)', height: '100%', background: 'none', border: 'none', outline: 'none', margin: 0, padding: '0 5px 0 0', color: 'white', fontFamily: 'inherit', fontSize: props.fontSize || 16, fontWeight: 400 }} value={props.value || ""} onChange={props.onChange} />

        </div>

        {/* Clear text button */}
        { props.value != ''
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px', cursor: 'pointer', zIndex: 5 }} onClick={props.onClear}>
                <img src={require('./close_white.svg')} style={{ width: 14, height: 14 }} />
                </div>
            : null
        }

    </div>

}