import { Color3, Color4, Engine, Scene } from "babylonjs"
import 'babylonjs-loaders'
import { clamp, inRange } from "lodash"
import Database from "../Database"
import { SharedComponents } from "../SharedComponents"
import { spiral } from "../Utils"
import HexItem from "./HexItem"
import { generateWaveMaterial } from "./Materials"
import SmoothedNumber from "./SmoothedNumber"

/**
 * Main code for the 3D component of the app.
 */
export default class App3D {

    /** @type {App3D} Singleton instance */
    static get shared() {
        if (!App3D._shared) App3D._shared = new App3D()
        return App3D._shared
    }

    /** Called on startup */
    async start() {

        // Store this instance
        SharedComponents.app3D = this

        // Disable Babylon's loader, we have our own one
        BABYLON.SceneLoader.ShowLoadingScreen = false

        // Create canvas
        this.canvas = document.createElement('canvas')
        this.canvas.style.cssText += 'position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; outline: none; '
        document.body.appendChild(this.canvas)

        // Create Babylon engine
        this.engine = new Engine(this.canvas, true, { preserveDrawingBuffer: true, stencil: true, antialias: true })

        // Create scene
        this.scene = new Scene(this.engine)
        await this.scene.createDefaultEnvironment({ createGround: false, createSkybox: false })
        this.scene.createDefaultCameraOrLight(true, false, true)
        this.scene.clearColor = new Color4(1, 1, 1, 1)

        // Add click handler
        this.scene.onPointerObservable.add((eventData, eventState) => {

            // Check if it's a tap event
            if (eventData.type != BABYLON.PointerEventTypes.POINTERTAP)
                return

            // Check if zoom level is showing cells
            if (this.scene.activeCamera.radius > 1.625)
                return

            // Pass click to hex item
            eventData.pickInfo?.pickedMesh?.hexItem?.onClick()

        })

        // Preload the meteor coin
        let url = require('./coin.glb')
        this.fallingHexContainer = await BABYLON.SceneLoader.LoadAssetContainerAsync(url, null)
        this.fallingHex = this.fallingHexContainer.meshes[0]
        this.fallingHex.scaling.setAll(0.001)

        // Enable the glow layer
        // var gl = new BABYLON.GlowLayer("glow", this.scene)

        // Setup lights
        this.scene.lights[0].specular = new BABYLON.Color3(1,1,1);
        //lights[0].groundColor = new BABYLON.Color3(1, 1,1);
        this.scene.lights[0].intensity = 1;
        this.scene.lights[0].rotation = new BABYLON.Vector3(0,0,0);

        this.lowerRadius = 1.3
        if (window.innerWidth > 1000 && window.innerWidth < 1200) this.lowerRadius = 1.325
        if (window.innerWidth > 800 && window.innerWidth <= 1000) this.lowerRadius = 1.35
        if (window.innerWidth > 600 && window.innerWidth <= 800) this.lowerRadius = 1.375
        if (window.innerWidth <= 600) this.lowerRadius = 1.4
        // Setup camera
        let ballCamera = this.scene.activeCamera;
        ballCamera.position = new BABYLON.Vector3(0,0,8);
        ballCamera.alpha += Math.PI;
        ballCamera.lowerRadiusLimit = this.lowerRadius;
        ballCamera.upperRadiusLimit = 8;
        ballCamera.panningSensibility = 0;
        ballCamera.wheelPrecision = 50;
        ballCamera.pinchPrecision = 200;
        ballCamera.angularSensibilityX = 200; // default: 1 (higher is slower)
        ballCamera.angularSensibilityY = 200; // default: 1 (higher is slower)
        ballCamera.heightSensibility = 200;
        ballCamera.radiusSensibility = 200;

        ballCamera.lowerAlphaLimit = -1000;
        ballCamera.upperAlphaLimit = 1000;
        ballCamera.lowerBetaLimit = Math.PI*0.25;
        ballCamera.upperBetaLimit = Math.PI*0.75;
        ballCamera.useAutoRotationBehavior = true;
        ballCamera.allowUpsideDown = false
        ballCamera.maxZ = 20

        // Setup level 2 zoom ball ball
        this.faceBall = BABYLON.MeshBuilder.CreateSphere("faceBall", {diameter:2.75});
        this.faceBall.material = new BABYLON.StandardMaterial("");
        this.faceBall.material.diffuseTexture = new BABYLON.Texture(require('./tile.jpg'));
        this.faceBall.material.diffuseTexture.vScale = 16;
        this.faceBall.material.diffuseTexture.uScale = 32;
        this.faceBall.material.emissiveTexture = this.faceBall.material.diffuseTexture
        this.faceBall.isPickable = false
        this.faceBall.visibility = 0
        this.faceBall.setEnabled(true)

        // Setup glow effect on the 2nd level ball
        // TODO: Disabled due to high GPU requirement shader
        // this.glowBall = BABYLON.MeshBuilder.CreateSphere("glowSphere", {diameter:2.76});
        //addEffect(this.scene, this.faceBall);

        this.godLightBall = BABYLON.MeshBuilder.CreateSphere("godLightBall", {diameter:2.699});
        this.godLightBall.material = new BABYLON.StandardMaterial("godMaterial", this.scene);
        this.godLightBall.material.diffuseColor = new BABYLON.Color3(0.0, 1.0, 0.0);
        this.godLightBall.material.emissiveColor = new BABYLON.Color3(1, 1, 1);
        // rad1.material.emissiveColor = new BABYLON.Color3(0.3, 0.1, 0.1);
        this.godLightBall.material.backFaceCulling = false;
        this.godLightBall.isPickable = false

        let godrays = new BABYLON.VolumetricLightScatteringPostProcess('godrays', 1, ballCamera, this.godLightBall, 100, BABYLON.Texture.BILINEAR_SAMPLINGMODE, this.engine, false);
        // godrays.mesh.material.diffuseTexture = new BABYLON.Texture(require('./sun.png'), this.scene, true, false, BABYLON.Texture.BILINEAR_SAMPLINGMODE);
        // godrays.mesh.material.diffuseTexture.hasAlpha = true;
        // godrays.mesh.material.diffuseTexture.uScale = 10;
        // godrays.mesh.material.diffuseTexture.vScale = 10;
        godrays.exposure = 0.3;

        // Create backdrop, only if not in vatom-face mode
        let isVatomFace = new URLSearchParams(location.search).get("face")
        if (!isVatomFace) {
            new BABYLON.PhotoDome('DOME', SharedComponents.isHighPowered ? require('./stadium.jpg') : require('./stadium-small.jpg'), {
                //useDirectMapping: false,
                resolution: 32,
                size: 20
            }, this.scene)
        }

        // Load base model for hex cells
        this.hexCellAsset = await BABYLON.SceneLoader.LoadAssetContainerAsync(require('./hex-cell.glb'), null, this.scene)

        // Load model
        // this.ballAssetContainer = await BABYLON.SceneLoader.LoadAssetContainerAsync(require('./outer-ball.glb'), null, this.scene)
        this.ballAssetContainer = await BABYLON.SceneLoader.LoadAssetContainerAsync(require('./soccer-ball.glb'), null, this.scene)
        this.ballAssetContainer.meshes[0].scaling.setAll(1.33)
        for (let mesh of this.ballAssetContainer.meshes) mesh.isPickable = false
        this.ballAssetContainer.addAllToScene()
        // this.ballAssetContainer.meshes[10].visibility = 0

        // Create dynamic grid container
        this.dynamicGridContainer = new BABYLON.TransformNode('GridContainer', this.scene)
        this.dynamicGridContainer.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL
        // this.dynamicGridContainer.position.set(0, 0, 0.75)
        // this.dynamicGridContainer.setEnabled(false)

        this.dynamicGridContainer.scaling.x = 1.4
        this.dynamicGridContainer.scaling.y = 1.4
        setTimeout(e => this.dynamicGridContainer.setEnabled(false), 1)

        // Create tiler
        this.dynamicGridTilter = new BABYLON.TransformNode('CellContainer', this.scene)
        this.dynamicGridTilter.parent = this.dynamicGridContainer
        this.dynamicGridTilter.position.set(0, 0, -1)
        this.dynamicGridTilter.rotation.x = 0.5

        // Create cell container
        this.cellContainer = new BABYLON.TransformNode('CellContainer', this.scene)
        this.cellContainer.parent = this.dynamicGridTilter
        this.cellContainer.scaling.setAll(0.03)

        // Use video or plain image depending on device power
        let effectTexture = null

        // Get effect video to from URL
        let bgVideo = new URLSearchParams(location.search).get("bg")
        
        if (SharedComponents.isHighPowered && !SharedComponents.isIOS) {

            // Create effect video
            let mediaElement = document.createElement('video')
            mediaElement.crossOrigin = 'anonymous'
            mediaElement.muted = true
            mediaElement.playsInline = true
            mediaElement.loop = true
            mediaElement.autoplay = true
            mediaElement.src = require('./animated-cell-bg.mp4')

            mediaElement.addEventListener('loadeddata', e => mediaElement.play())
            mediaElement.addEventListener('canplay', e => mediaElement.play())
            mediaElement.load()

            // Create effect texture
            effectTexture = new BABYLON.VideoTexture('videotexture', mediaElement, this.scene)

        } else {

            // Use single image texture
            effectTexture = new BABYLON.Texture(require('./animated-cell-bg.jpg'), this.scene)
            

        }

        // Create dynamic grid background color
        this.dynamicGridBackgroundColor = BABYLON.MeshBuilder.CreatePlane("facePlane", { height: 3, width: 3 }, this.scene);
        this.dynamicGridBackgroundColor.position.z = 0.1
        this.dynamicGridBackgroundColor.parent = this.dynamicGridTilter
        this.dynamicGridBackgroundColor.visibility = 1
        this.dynamicGridBackgroundColor.material = new BABYLON.StandardMaterial('')
        this.dynamicGridBackgroundColor.material.diffuseColor = new BABYLON.Color3(0, 0, 0)
        this.dynamicGridBackgroundColor.material.emissiveTexture = effectTexture
        this.dynamicGridBackgroundColor.material.emissiveTexture.uScale = 10
        this.dynamicGridBackgroundColor.material.emissiveTexture.vScale = 10
        this.dynamicGridBackgroundColor.material.specularColor = new BABYLON.Color3(0, 0, 0)

        
        // Workaround for Safari, play the video on touch start
        // this.canvas.addEventListener('pointerdown', e => {
        //     this.dynamicGridBackgroundColor.material.emissiveTexture.video.play()
        // })

        this.dynamicGridBackgroundColor2 = BABYLON.MeshBuilder.CreatePlane("plane", { height: 10, width: 10 }, this.scene);
        this.dynamicGridBackgroundColor2.position.z = 0
        this.dynamicGridBackgroundColor2.parent = this.dynamicGridTilter
        this.dynamicGridBackgroundColor2.visibility = 1
        this.dynamicGridBackgroundColor2.material = new BABYLON.StandardMaterial('')
        this.dynamicGridBackgroundColor2.material.emissiveTexture = new BABYLON.Texture(require('./tile.png'), this.scene)
        this.dynamicGridBackgroundColor2.material.emissiveTexture.uScale = 55.5
        this.dynamicGridBackgroundColor2.material.emissiveTexture.vScale = 55.55
        this.dynamicGridBackgroundColor2.material.disableLighting = true
        this.dynamicGridBackgroundColor2.material.transparencyMode = BABYLON.Material.MATERIAL_ALPHABLEND
        this.dynamicGridBackgroundColor2.material.opacityTexture = this.dynamicGridBackgroundColor2.material.emissiveTexture
        this.dynamicGridBackgroundColor2.position.x = 0.0005
        this.dynamicGridBackgroundColor2.position.y = -0.093

        // this.glowLayer = new BABYLON.GlowLayer("glow", this.scene, {
        //     // mainTextureRatio: 1,
        //     // mainTextureFixedSize: 128,
        //     // blurKernelSize: 64,
        // });
        // this.glowLayer.customEmissiveColorSelector = function(mesh, subMesh, material, result) {
        //     if (material.emissiveColor) {
        //         if (material.emissiveColor.r === 0 &&
        //             material.emissiveColor.g === 0 &&
        //             material.emissiveColor.b === 0)
        //         {
        //             result.set(255, 255, 35, 0.07);
        //         }
        //         else {
        //             result.set(255, 140, 35, 0.07);
                    
        //         }
        //     }
            
        //     //result.set(255, 140, 35, 0.01);
        // }
        //this.dynamicGridBackgroundColor2.material._activeEffect = this.glowLayer
       // this.dynamicGridBackgroundColor2._adde
       // addEffect(this.scene, this.dynamicGridBackgroundColor2);

        // this.dynamicGridBackgroundColor3 = BABYLON.MeshBuilder.CreatePlane("plane", { height: 10, width: 10 }, this.scene);
        // this.dynamicGridBackgroundColor3.position.z = 0.01
        // this.dynamicGridBackgroundColor3.position.x = 0.0005
        // this.dynamicGridBackgroundColor3.position.y = -0.2
        // this.dynamicGridBackgroundColor3.parent = this.dynamicGridTilter
        // this.dynamicGridBackgroundColor3.visibility = 1
        // this.dynamicGridBackgroundColor3.material = new BABYLON.StandardMaterial('')
        // addEffect(this.scene, this.dynamicGridBackgroundColor3);
        // Add light to illuminate the grid items
        // let gridLight = new BABYLON.PointLight("grid-light", new BABYLON.Vector3(0, -0.3, -1.3), this.scene)
        // gridLight.parent = this.dynamicGridContainer
        // gridLight.intensity = 0.5

        // let o = BABYLON.MeshBuilder.CreateBox("", { size: 0.01 }, this.scene)
        // o.parent = gridLight.parent
        // o.position = gridLight.position

        // Create dynamic grid background effect
        // this.dynamicGridBackgroundEffect = BABYLON.MeshBuilder.CreatePlane("facePlane", { height: 10, width: 10 }, this.scene);
        // this.dynamicGridBackgroundEffect.material = generateWaveMaterial()
        // this.dynamicGridBackgroundEffect.parent = this.dynamicGridContainer

        // Start rendering
        this.engine.runRenderLoop(this.onRender.bind(this))

        // Add resize listener
        window.addEventListener('resize', this.onResize.bind(this))

        // Add pointer event listeners
        this.canvas.addEventListener('pointerdown', this.onPointerDown.bind(this))
        this.canvas.addEventListener('pointermove', this.onPointerMove.bind(this))
        this.canvas.addEventListener('pointerup', this.onPointerUp.bind(this))
        this.canvas.addEventListener('pointercancel', this.onPointerCancel.bind(this))

    }

    /** Called on resize */
    onResize() {

        // Resize canvas
        this.engine.resize()

    }

    /** Actions to perform on specific distances from the ball */
    zoomLevelActions = [

        {from: this.lowerRadius, to: 1.6, do: (zoomFactor) => {
            
            // Layer 1: Dyamic grid layer
            this.godLightBall.visibility = 0
            this.faceBall.visibility = 0
            // this.glowBall.visibility = 0
            //this.dynamicGridBackgroundColor.visibility = 1
            this.dynamicGridContainer.setEnabled(true)
            for (let mesh of this.ballAssetContainer.meshes) mesh.visibility = 0

            if (!this.placedAvatar && this.ownItem.model) {
                let ease = new BABYLON.QuadraticEase()
                ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT)
                BABYLON.Animation.CreateAndStartAnimation(
                    "", 
                    this.ownItem.model, 
                    "position", 
                    60, 
                    150, 
                    this.ownItem.model.position, 
                    new BABYLON.Vector3(this.ownItem.model.position.x, 0, 0), 
                    0, 
                    ease, 
                    () => {},
                    this.scene
                )
                this.placedAvatar = true
            }
        }},

        {from: 1.6, to: 1.85, do: (zoomFactor) => {
            
            // Layer 1 to 2: Dynamic grid transitioning to fake overlay layer
            // this.godLightBall.visibility = 0
            // this.faceBall.visibility = zoomFactor
            // //this.godLightBall.visibility = this.faceBall.visibility
            // this.dynamicGridContainer.setEnabled(true)
            // for (let mesh of this.ballAssetContainer.meshes) mesh.visibility = 0
            
            // this.godLightBall.visibility = 0
            this.faceBall.visibility = zoomFactor
            //this.godLightBall.visibility = this.faceBall.visibility
            //this.dynamicGridBackgroundColor.visibility = 1
            this.dynamicGridContainer.setEnabled(true)
            for (let mesh of this.ballAssetContainer.meshes) mesh.visibility = 0

        }},

        {from: 1.85, to: 2, do: (zoomFactor) => {
            
            // Layer 2: Fake overlay layer
            this.godLightBall.visibility = 0
            this.faceBall.visibility = 1
            // this.glowBall.visibility = 1
            this.dynamicGridContainer.setEnabled(false)
            for (let mesh of this.ballAssetContainer.meshes) mesh.visibility = 1

        }},

        {from: 2, to: 3.5, do: (zoomFactor) => {
            
            // Layer 2 to 3: Static ball layer transitioning to fake overlay layer
            this.godLightBall.visibility = 1
            this.faceBall.visibility = 1 - zoomFactor
            // this.glowBall.visibility = this.faceBall.visibility
            this.dynamicGridContainer.setEnabled(false)
            for (let mesh of this.ballAssetContainer.meshes) mesh.visibility = 1

        }},

        {from: 3.5, to: 8, do: (zoomFactor) => {
            if (!this.placedAvatar && this.ownItem?.model) {
                this.ownItem.model.position.z = -5
                this.ownItem.model.position.y = 1
            }
            // Layer 3: Static ball layer
            this.godLightBall.visibility = 1
            this.faceBall.visibility = 0
            // this.glowBall.visibility = 0
            //this.dynamicGridBackgroundColor.visibility = 0
            this.dynamicGridContainer.setEnabled(false)
            for (let mesh of this.ballAssetContainer.meshes) mesh.visibility = 1

        }},

    ]

    

    /** Called every frame */
    onRender() {

        // Calculate delta
        let now = Date.now()
        let delta = Math.min(1, (now - (this._lastFrameTime || now)) / 1000)
        this._lastFrameTime = now

        // Get current zoom factor between 0 (dynamic grid) and 1 (static ball)
        let minZoom = 0.5
        let maxZoom = 8

        // Check what to do
        let zoomAmount = this.scene.activeCamera.radius//position.length()

        // Update zoom level actions if they changed
        if (this._lastZoomAmount != zoomAmount) {
            this._lastZoomAmount = zoomAmount

            for (let zoomAction of this.zoomLevelActions) {

                // Check if within range
                if (!inRange(zoomAmount, zoomAction.from, zoomAction.to))
                    continue

                // Found the right action to perform
                zoomAction.do(normalizeBetween(zoomAmount, zoomAction.from, zoomAction.to))
                break

            }
        }

        // Update dynamic grid every so often
        if (!this.lastGridUpdate || now - this.lastGridUpdate > 500) {
            this.lastGridUpdate = now
            this.updateDynamicGrid()
        }   

        // Move cell container based on offset, and apply smoothing
        if (!this._smoothedOffsetX) this._smoothedOffsetX = new SmoothedNumber()
        if (!this._smoothedOffsetY) this._smoothedOffsetY = new SmoothedNumber()
        this._smoothedOffsetX.set(-this.gridOffset.x * 0.03)
        this._smoothedOffsetY.set(-this.gridOffset.y * 0.03)
        this._smoothedOffsetX.update(delta)
        this._smoothedOffsetY.update(delta)
        this.cellContainer.position.x = this._smoothedOffsetX.value
        this.cellContainer.position.y = this._smoothedOffsetY.value
        this.dynamicGridBackgroundColor2.position.x = this._smoothedOffsetX.value + 0.00025 + this._smoothedOffsetX.value/1000
        this.dynamicGridBackgroundColor2.position.y = this._smoothedOffsetY.value * 0.997 - 0.003

        // Update cell background to match movement
       // this.dynamicGridBackgroundColor.material.emissiveTexture.uOffset = -this.cellContainer.position.x * 3
       // this.dynamicGridBackgroundColor.material.emissiveTexture.vOffset = -this.cellContainer.position.y * 3

        // Debug: Show active center position on grid
        // if (!this.gridCenter) {
        //     this.gridCenter = BABYLON.MeshBuilder.CreateCylinder("Hex", { tessellation: 6, diameter: 1, height: 0.2 })
        //     this.gridCenter.rotation = new BABYLON.Vector3(Math.PI/2, 0, 0)
        //     this.gridCenter.parent = this.cellContainer
        // }
        // this.gridCenter.position.set(this.gridOffset.x, this.gridOffset.y, 0)

        // Render the scene
        this.scene.render()

        // If the zoom level has changed away from the default, hide the pinch to zoom message
        if (zoomAmount < 7.5)
            SharedComponents.app.hideZoomToast()

        // If zoomed in to dynamic grid, lock the camera rotation
        if (zoomAmount <= 1.625) {
            this.scene.activeCamera.beta = Math.PI/2
        }

        // Snap zoom to not allow the user to see the half-zoomed-in dynamic grid
        // if (this.isAnimatingZoom) {

        //     // Ignore while animating

        // } else if (!this.hasLockedZoom && zoomAmount < 2) {
            
        //     // Lock zoom in to dynamic grid
        //     this.enterDynamicGrid()

        // } else if (this.hasLockedZoom && zoomAmount > 0.7) {
            
        //     // Unlock zoom to the static ball
        //     this.exitDynamicGrid()

        // }

        // Create meteor every so often
        if (now - (this.lastMeteor || 0) > 2000) {
            this.lastMeteor = now
            if (zoomAmount >= 3.5) {
                this.createMeteor()
            }
        }

    }

    /** Zoom into the dynamic grid */
    enterDynamicGrid() {

        // Stop if already in place
        if (this.scene.activeCamera.radius <= 1.2)
            return

        // Mark it
        this.hasLockedZoom = true
        this.isAnimatingZoom = true

        // Snap zoom to fully zoomed in... Prevent camera input during the animation
        this.scene.activeCamera.detachControl()

        // Animate in
        let ease = new BABYLON.QuadraticEase()
        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT)
        BABYLON.Animation.CreateAndStartAnimation(
            "", 
            this.scene.activeCamera, 
            "radius", 
            60, 
            60, 
            this.scene.activeCamera.radius, 
            0.5, 
            0, 
            ease,
            () => {

                // Entering zoom locked phase
                setTimeout(e => this.isAnimatingZoom = false, 250) // <-- Whyyyyy, Babylon, does the radius not end on the last frame on the next render cycle?
                this.scene.activeCamera.attachControl()

                // Prevent camera movement which affects lights
                this.scene.activeCamera.lowerBetaLimit = Math.PI/2
                this.scene.activeCamera.upperBetaLimit = Math.PI/2

            }, 
            this.scene
        )

    }

    /** Zoom out to the static ball */
    exitDynamicGrid() {

        // Stop if already in place
        if (this.scene.activeCamera.radius < 8)
            return

        // Mark it
        this.hasLockedZoom = false
        this.isAnimatingZoom = true

        // Snap zoom to zoomed out... Prevent camera input during the animation
        this.scene.activeCamera.detachControl()

        // Animate in
        let ease = new BABYLON.QuadraticEase()
        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT)
        BABYLON.Animation.CreateAndStartAnimation(
            "", 
            this.scene.activeCamera, 
            "radius", 
            60, 
            60, 
            this.scene.activeCamera.radius, 
            8,
            0, 
            ease,
            () => {

                // Exiting zoom locked phase
                setTimeout(e => this.isAnimatingZoom = false, 250) // <-- Whyyyyy, Babylon, does the radius not end on the last frame on the next render cycle?
                this.scene.activeCamera.attachControl()

                // Allow camera movement
                this.scene.activeCamera.lowerBetaLimit = Math.PI*0.25;
                this.scene.activeCamera.upperBetaLimit = Math.PI*0.75;

            },
            this.scene
        )

    }

    /** Dynamic grid items */
    gridItems = []

    /** Grid offset from origin */
    gridOffset = new BABYLON.Vector2(
        HexItem.xTo3D(0, 0),
        HexItem.yTo3D(0, 0)
    )

    /** Create and load dynamic grid items */
    async updateDynamicGrid() {

        // Prevent overlap
        if (this.gridIsUpdating) return
        this.gridIsUpdating = true

        // Do the update
        try {

            // Run the update again if it was interrupted
            while (true) {
                let wasInterrupted = await this.updateDynamicGrid2()
                if (!wasInterrupted) break
            }

        } catch (err) {
            console.warn(`[App3D] Failed during grid update:`, err)
        }

        // Done
        this.gridIsUpdating = false

    }

    async updateDynamicGrid2() {

        // Create own item
        if (this.gridItems.length == 0) {

            // Create own item
            let ownItem = new HexItem(this.cellContainer, 0, 0, Database.shared.currentUser)
            this.ownItem = ownItem
            this.gridItems.push(ownItem)

        }

        // Create iteration counter
        this.iteration = (this.iteration || 0) + 1

        // Remove all which are offscreen
        let startX = this.gridOffset.x
        let startY = this.gridOffset.y
        let centerX = HexItem.xFrom3D(this.gridOffset.x, this.gridOffset.y)
        let centerY = HexItem.yFrom3D(this.gridOffset.x, this.gridOffset.y)
        let radius = 20
        for (let i = 0 ; i < this.gridItems.length ; i++) {

            // Check if within view
            let cell = this.gridItems[i]
            if (cell.x >= centerX - radius && cell.x <= centerX + radius && cell.y >= centerY - radius && cell.y <= centerY + radius)
                continue

            // Not in view, remove it
            cell.remove()

        }

        // Go through all cells based on distance from center
        let numCellsBefore = this.gridItems.length
        for (let position of spiral(radius, radius*3)) {

            // Certain cells are skipped due to the current user's cell being double sized:
            let cellX = centerX + position.x
            let cellY = centerY + position.y

            // Check if within view
            if (cellX < centerX - radius || cellX > centerX + radius || cellY < centerY - radius || cellY > centerY + radius)
                continue

            // Only do one database fetch per cycle, otherwise just add all
            let didFetchFromDatabase = await this.addGridCellAt(cellX, cellY)

            // Stop if the user moved while we were loading
            if (startX != this.gridOffset.x || startY != this.gridOffset.y)
                return true

        }

        // Remove cells that are no longer in range
        let originKeepRadius = 4
        let numVisible = 0
        for (let cell of this.gridItems) {

            // Increase counter
            if (cell.isCreated)
                numVisible += 1

            // Don't ever remove the area near the current user
            // if (cell.x > -originKeepRadius && cell.x < originKeepRadius && cell.y > -originKeepRadius && cell.y < originKeepRadius)
            //     continue

            // Don't remove if it was touched this iteration
            if (cell.iteration == this.iteration)
                continue

            // Cell can be removed
            cell.remove()
            // this.gridItems = this.gridItems.filter(c => c != cell)
            
        }

        // Done
        if (this._lastGridItems != this.gridItems.length) {
            this._lastGridItems = this.gridItems.length
            console.debug(`[HexGrid] Update complete: cells=${this.gridItems.length} visible=${numVisible}`)
        }

    }

    /** Slow down creation a bit to maintain frame rate */
    async slowDownCreation() {

        // Increase num created per frame
        let numCreatedPerFrame = SharedComponents.isHighPowered ? 2 : 1
        this.numCreatedThisFrame = (this.numCreatedThisFrame || 0) + 1
        if (this.numCreatedThisFrame >= numCreatedPerFrame) {

            // Too many created in a single frame, delay it a bit
            this.numCreatedThisFrame = 0
            await new Promise(cb => setTimeout(cb, 10))

        }

    }

    /** Adds a new grid cell. Returns true if it resulted in a database fetch. */
    async addGridCellAt(x, y) {

        // Check if cell exists at this position
        let existingCell = this.gridItems.find(i => i.x == x && i.y == y)
        if (existingCell) {
            existingCell.iteration = this.iteration
            if (!existingCell.isCreated && existingCell.user) {
                existingCell.create()
                await this.slowDownCreation()
            }
            return false
        }

        // Density based on if this is a high-powered device or not
        let userDensity = SharedComponents.isHighPowered ? 0.5 : 0.25

        // Chance of creating a blank cell
        let user = null
        let didDatabaseFetch = false
        let blankCellChance = Database.shared.isNextUserAFriend ? 0 : 1-userDensity
        if (Math.random() < blankCellChance) {

            // Create blank cell
            user = null

        } else {

            // Fetch next user in the queue
            let didDatabaseFetch = Database.shared.userQueue.length == 0
            user = await Database.shared.getNextUser()

            // Don't allow our own user to repeat
            if (user.id == Database.shared.userID && (x != 0 || y != 0))
                user = null

        }

        // Create new cell
        let cell = new HexItem(this.cellContainer, x, y, user)
        cell.iteration = this.iteration
        cell.create()
        this.gridItems.push(cell)
        // console.debug(`[App3D] Created grid item at (${x}, ${y}): user=${user?.id}`)

        // Slow this down so only one user is created per frame, if not a high powered device
        if (user)
            await this.slowDownCreation()

        // Done
        return didDatabaseFetch

    }

    /** Handle pointer event */
    onPointerDown(e) {

        // Stop if zoom is not enough
        let zoomAmount = this.scene.activeCamera.position.length()
        if (zoomAmount > 1.625)
            return

        // Store pointer
        this.offsetDragPointerID = e.pointerId
        this.lastPointerX = e.screenX / window.innerHeight
        this.lastPointerY = e.screenY / window.innerHeight

        // Disable the camera's controls
        // this.scene.activeCamera.detachControl()
        // setTimeout(e => this.scene.activeCamera.attachControl(), 10)

    }

    onPointerMove(e) {

        // Check if it's our pointer
        if (e.pointerId != this.offsetDragPointerID)
            return

        // Calculate 

        // Update movement
        this.gridOffset.x += (this.lastPointerX - (e.screenX / window.innerHeight)) * 15
        this.gridOffset.y -= (this.lastPointerY - (e.screenY / window.innerHeight)) * 15
        this.lastPointerX = (e.screenX / window.innerHeight)
        this.lastPointerY = (e.screenY / window.innerHeight)
        // console.log(this.gridOffset.x, this.gridOffset.y)

    }

    onPointerUp(e) {

        // Check if it's our pointer
        if (e.pointerId != this.offsetDragPointerID)
            return

        // Remove pointer
        this.offsetDragPointerID = null

    }

    onPointerCancel(e) {
        this.onPointerUp(e)
    }

    /** Called by the UI to focus the current user */
    goToCurrentUser() {

        // Ensure zoomed in
        this.enterDynamicGrid()

        // Go to origin
        let x = HexItem.xTo3D(0, 0)
        let y = HexItem.yTo3D(0, 0)
        this.gridOffset.set(x, y)

    }

    /** Called to display a specific user on the 3D grid. If the user doesn't exist, it will be loaded and added in at a random empty slot */
    showUser(user) {

        // First zoom in to the map
        this.enterDynamicGrid()

        // Find the user on the map
        let foundCell = this.gridItems.find(c => c.user?.id == user.id)
        if (foundCell) {

            // Go to this location
            let x = HexItem.xTo3D(foundCell.x, foundCell.y)
            let y = HexItem.yTo3D(foundCell.x, foundCell.y)
            this.gridOffset.set(x, y)
            console.debug(`[HexGrid] Displaying existing user: id=${user.id} x=${x} y=${y}`)
            return

        }

        // Not found, find an empty spot on the map
        let x = 0
        let y = 0
        let radius = 0
        while (true) {

            // Check if empty at this coordinate
            let foundItem = this.gridItems.find(c => c.x == x && c.y == y)
            if (!foundItem)
                break

            // Slot is full, increase radius and try again
            radius += 10
            let angle = Math.random() * Math.PI*2
            x = Math.floor(Math.cos(angle) * radius)
            y = Math.floor(Math.sin(angle) * radius)

        }

        // Create new user at this position
        let cell = new HexItem(this.cellContainer, x, y, user)
        cell.create()
        this.gridItems.push(cell)

        // Go to the new cell's location location
        let x2 = HexItem.xTo3D(cell.x, cell.y)
        let y2 = HexItem.yTo3D(cell.x, cell.y)
        this.gridOffset.set(x2, y2)
        console.debug(`[HexGrid] Displaying new user: id=${user.id} x=${x2} y=${y2}`)

    }

    // Create a new incoming cell "meteor" and display the animation
    async createMeteor() {


        if (this.creatingMeteor) return

        this.creatingMeteor = true
        // Pick a random user from the users who have already been loaded, but not the current user
       // let loadedCells = this.gridItems.filter(c => c.user && c.model && !(c.x == 0 && c.y == 0))
       // if (loadedCells.length == 0) return
      //  let randomExistingCell = loadedCells[Math.floor(Math.random() * loadedCells.length)]

        // Clone the cell
        //console.debug(`[3D] Displaying meteor for user: ${randomExistingCell.user.id}`)
       // let fallingHex = randomExistingCell.model.clone()
        let fallingHex = this.fallingHex.clone()
        this.fallingHexContainer.addAllToScene()
        fallingHex.parent = null

        // Start position of animation
        let startFrom = new BABYLON.Vector3(Math.random()*6-3,Math.random()*6,Math.random()*6-6);
        fallingHex.position = startFrom;
        fallingHex.scaling.setAll(0.1)

        // Animate position change
        const fall = new BABYLON.Animation("fall", "position", 20, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        const keyFrames = [];
        keyFrames.push({frame: 0, value: startFrom});
        keyFrames.push({frame: 30, value: new BABYLON.Vector3(0,0,0)});
        fall.setKeys(keyFrames);

        // Animate rotation change
        const rot = new BABYLON.Animation("rot", "rotation", 20, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
        var keyFramesR = [];
        keyFramesR.push({frame: 0, value: new BABYLON.Vector3(0,0,0)});
        keyFramesR.push({frame: 30, value: new BABYLON.Vector3(Math.random()*Math.PI*2,Math.random()*Math.PI*2,Math.random()*Math.PI*2)});
        rot.setKeys(keyFramesR);

        // Start animation
        this.scene.beginDirectAnimation(fallingHex, [fall, rot], 0, 30, false, 1, ()=>{

            // Once complete, dispose of the new tile
            fallingHex.dispose();

        });

        this.creatingMeteor = false

    }
    
}

const addEffect = async function(scene, mesh) {
    //const nodeMaterial = await BABYLON.NodeMaterial.ParseFromSnippetAsync("2VUFZ8#2", scene);
    const nodeMaterial = generateWaveMaterial()
    nodeMaterial.build(true);
    nodeMaterial.needDepthPrePass = true;
    nodeMaterial.backFaceCulling = false;

    mesh.material = nodeMaterial;

    updateInputBlock(nodeMaterial, {
        baseColorStrength: 0.5,
        baseColor: BABYLON.Color3.FromHexString('#E3E2EC'),
        glowColor: BABYLON.Color3.FromHexString('#ffbe4d'),
       // speed: 0.01,
        // color: new BABYLON.Color3(1,0,0,)
    });

    // addHighlight(scene, mesh);
    addGlow(scene, mesh, 0.2);
}
const addHighlight = function(scene, mesh) {
    const gl = new BABYLON.HighlightLayer("highlight", scene);
    gl.addMesh(mesh, BABYLON.Color3.FromHexString('#E3E2EC'));
    // gl.addMesh(mesh, new BABYLON.Color3(.5,.5,.5));
}
const addGlow = function(scene, mesh, intensity) {
    const glow = new BABYLON.GlowLayer("glow", scene, {
        // mainTextureRatio: 1,
        // mainTextureFixedSize: 128,
        // blurKernelSize: 64,
    });
    glow.intensity = intensity;
    glow.referenceMeshToUseItsOwnMaterial(mesh);

    // glow.customEmissiveColorSelector = function(mesh, subMesh, material, result) {
    //     result.set(0, 0, 1, 0.1);
    // }
    return glow;
}
const updateInputBlock = function(mat, config) {
    const keys = Object.keys(config);
    // if (glowBall && glowBall.enabled) {
        keys.forEach((key) => {
            const targetBlock = mat.getInputBlockByPredicate(b => b.name === key);
            targetBlock.value = config[key];
        });
    // }
}

/** Calculates a normalized value 0..1 between two numbers */
function normalizeBetween(number, min, max) {
    return (clamp(number, min, max) - min) / (max - min)
}

//** Generates a number between a max and min */
function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}