import React from 'react'

/** Displays an image or content with a hex border */
export function HexOutline(props) {

    // Render UI
    let thickness = props.hexThickness || 15
    let shadowWeight = props.hexShadowWeight ?? 0.75
    return <div style={Object.assign({ position: 'relative', width: 64, height: 64 }, props.style)} onClick={props.onClick}>

        {/* Hex icon background 1 */}
        <svg viewBox="0 0 483.013 483.013" style={{ margin: 'auto', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
            <path d="M477.043,219.205L378.575,48.677c-7.974-13.802-22.683-22.292-38.607-22.292H143.041c-15.923,0-30.628,8.49-38.608,22.292
                L5.971,219.205c-7.961,13.801-7.961,30.785,0,44.588l98.462,170.543c7.98,13.802,22.685,22.293,38.608,22.293h196.926
                c15.925,0,30.634-8.491,38.607-22.293l98.469-170.543C485.003,249.99,485.003,233.006,477.043,219.205z" fill={props.hexColor || '#FFF'} />
        </svg>
        <svg viewBox="0 0 483.013 483.013" style={{ margin: 'auto', width: '100%', height: '100%', position: 'absolute', top: 6, left: 0, opacity: shadowWeight }}>
            <path d="M477.043,219.205L378.575,48.677c-7.974-13.802-22.683-22.292-38.607-22.292H143.041c-15.923,0-30.628,8.49-38.608,22.292
                L5.971,219.205c-7.961,13.801-7.961,30.785,0,44.588l98.462,170.543c7.98,13.802,22.685,22.293,38.608,22.293h196.926
                c15.925,0,30.634-8.491,38.607-22.293l98.469-170.543C485.003,249.99,485.003,233.006,477.043,219.205z" fill={props.hexColor || '#FFF'} />
        </svg>

        {/* Hex icon background 2 */}
        <svg viewBox="0 0 483.013 483.013" style={{ margin: 'auto', width: `calc(100% - ${thickness*2}px)`, height: `calc(100% - ${thickness*2}px)`, position: 'absolute', top: thickness, left: thickness }}>
            <path d="M477.043,219.205L378.575,48.677c-7.974-13.802-22.683-22.292-38.607-22.292H143.041c-15.923,0-30.628,8.49-38.608,22.292
                L5.971,219.205c-7.961,13.801-7.961,30.785,0,44.588l98.462,170.543c7.98,13.802,22.685,22.293,38.608,22.293h196.926
                c15.925,0,30.634-8.491,38.607-22.293l98.469-170.543C485.003,249.99,485.003,233.006,477.043,219.205z"/>
        </svg>

        {/* User image mask */}
        <div style={{ margin: 'auto', width: `calc(100% - ${thickness*2+10}px)`, height: `calc(100% - ${thickness*2+10}px)`, position: 'absolute', top: thickness+5, left: thickness+5, overflow: 'hidden', backgroundColor: 'black',
            maskImage: `url(${require('./hexagonal.svg')})`, maskPosition: 'center', maskSize: 'contain', maskRepeat: 'no-repeat', 
            WebkitMaskImage: `url(${require('./hexagonal.svg')})`, WebkitMaskPosition: 'center', WebkitMaskSize: 'contain', WebkitMaskRepeat: 'no-repeat' }}>

            {/* Content */}
            {props.children}

        </div>

    </div>

}