import { Vector3 } from "babylonjs"
import Database from "../Database"
import { SharedComponents } from "../SharedComponents"

/**
 * Represents an item on the grid
 */
export default class HexItem {

    /** User document */
    user = {}

    /** Position */
    x = 0
    y = 0

    /** @type {BABYLON.Mesh} Background / border element */
    bg = null

    /** Convert cell coordinate to and from 3D map coordinate */
    static xTo3D(x, y) { return x * 1.5 + (y % 2 == 0 ? 1.5/2 : 0) }
    static yTo3D(x, y) { return y * 0.43 }
    static xFrom3D(x, y) { return Math.floor(x / 1.5 + (Math.round(y) % 2 == 0 ? 1.5/2 : 0)) }
    static yFrom3D(x, y) { return Math.floor(y / 0.43) }

    /** @private Setup reusable materials */
    static setupReusableMaterials() {

        // Only do once
        if (HexItem.hasSetupMaterials) return
        HexItem.hasSetupMaterials = true

        // Function to create a material from a color value in the range of 0-255
        const createFromColor = (r, g, b, glow = 0.5) => {
            let m = new BABYLON.PBRMaterial()
            // m.disableLighting = true
            m.albedoColor = new BABYLON.Color3(r/255, g/255, b/255)
            // m.specularColor = new BABYLON.Color3(1, 1, 1)
            m.emissiveColor = m.albedoColor.scale(glow)
            m.metallic = 0.85
            return m
        }

        // Create color materials
        HexItem.goldMaterial = createFromColor(255, 140, 35)
        HexItem.magentaMaterial = createFromColor(85, 39, 39)
        HexItem.silverMaterial = createFromColor(87, 87, 87, 0.75)
        HexItem.blueMaterial = createFromColor(42, 68, 87, 1)
        HexItem.blackMaterial = createFromColor(0, 0, 0, 1)

        HexItem.glowLayer = new BABYLON.GlowLayer("glow", SharedComponents.app3D.scene, {
            // mainTextureRatio: 1,
            // mainTextureFixedSize: 128,
            // blurKernelSize: 64,
        });
        HexItem.glowLayer.customEmissiveColorSelector = function(mesh, subMesh, material, result) {
            if (mesh.id === "Clone of FIFA_hexcoin_min_border.FIFA_hexcoin_min_border") {
                result.set(255, 140, 35, 0.8);
            } else {
                result.set(0, 0, 0, 0);
            }
        }

    }

    /** Create new item */
    constructor(parent, x, y, user) {

        // Create item
        this.x = x
        this.y = y
        this.user = user
        this.parent = parent

    }

    /** True if created */
    get isCreated() {
        return !!this.model
    }

    /** Create item */
    create() {

        // Stop if already created
        if (this.isCreated)
            return

        // Hex grid position shifting
        let hexShiftX = HexItem.xTo3D(this.x, this.y)// * 1.5 + (y % 2 == 0 ? 1.5/2 : 0)
        let hexShiftY = HexItem.yTo3D(this.x, this.y)// * 0.43

        // Stop if it's a blank one
        if (!this.user)
            return

        // Setup reusable materials
        HexItem.setupReusableMaterials()

        // Create a clone of the hex cell asset
        let entries = SharedComponents.app3D.hexCellAsset.instantiateModelsToScene(
            name => `${name} > user:${this.user?.id}`,  // <-- Name converter
            true,                                       // <-- Clone materials as well
            // node => false
        )

        // Update model
        this.model = entries.rootNodes[0]
        this.model.scaling.setAll(0.235)
        this.model.parent = this.parent
        this.model.position.set(hexShiftX, hexShiftY, 0)
        this.model.hexItem = this

        // Create material for image
        let modelChildren = this.model.getChildren(null, false)
        this.image = modelChildren.find(m => m.name?.startsWith('FIFA_hexcoin_min_image'))
        this.image.material.albedoTexture = new BABYLON.Texture(this.user.photoLowRes, SharedComponents.app3D.scene)
        this.image.hexItem = this
        this.image.scaling.setAll(0.0086)
        
        // Store border material
        this.border = modelChildren.find(m => m.name?.startsWith('FIFA_hexcoin_min_border'))
        this.border.material = new BABYLON.StandardMaterial('material:border', SharedComponents.app3D.scene)
        this.border.material.diffuseTexture = new BABYLON.Texture(require('./gold.png'), SharedComponents.app3D.scene)
        this.border.hexItem = this
        this.border.emissiveColor = HexItem.goldMaterial
        this.border.material._activeEffect = HexItem.glowLayer
        
        // Set material based on item type
        //this.border.material = HexItem.goldMaterial
        // if (this.x == 0 && this.y == 0) {

        //     // Own item
        //     this.border.material = HexItem.goldMaterial
        //     // let ownItemScale = 1
        //     // this.bg.scaling.setAll(ownItemScale)
        //     // this.img.scaling.set(ownItemScale, -ownItemScale, ownItemScale)
        //     // this.img.position.z *= ownItemScale

        //     // this.img.position.z -= 0.2
        //     // this.bg.position.z -= 0.2

        // } else if (Database.shared.currentUser?.friends?.includes(this.user.id)) {

        //     // Friend item
        //     this.border.material = HexItem.magentaMaterial
        
        // } else if (this.user.discoverable) {

        //     // Celebrity item
        //     this.border.material = HexItem.goldMaterial
        
        // } else {

        //     // Everyone else
        //     this.border.material = HexItem.silverMaterial

        // }

    }

    /** Remove this item */
    remove() {

        // Dispose of the items
        this.border?.dispose(false, false)
        this.border = null
        this.image?.dispose(false, true)
        this.image = null
        this.model?.dispose(false, true)
        this.model = null

    }

    /** Called when the user clicks this item */
    onClick() {
        // Set material based on item type
        let color = ''
        color = '#ffbe4d'

        // if ((this.x == 0 && this.y == 0) || this.user.discoverable) {

        //     // Own item or celebrity
        //     color = '#ffbe4d'

        // } else if (Database.shared.currentUser?.friends?.includes(this.user.id)) {

        //     // Friend item
        //     color = '#883e3e'
        
        // } else {

        //     // Everyone else
        //     color = '#969696'

        // }

        // Show the user
        SharedComponents.app.displayUser(this.user, color)

    }

}
