import { initializeApp } from 'firebase/app'
import { collection, collection, collection, collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, startAfter, where } from 'firebase/firestore'

/**
 * This class connects to the Firebase database and retrieves users to display in the dynamic grid.
 */
export default class Database {

    /** @type {Database} Singleton instance */
    static get shared() {
        if (!Database._shared) Database._shared = new Database()
        return Database._shared
    }

    /** Current campaign ID */
    campaignID = new URLSearchParams(location.search).get("campaign")

    /** Current user's ID */
    userID = new URLSearchParams(location.search).get("user")

    /** User document */
    currentUser = null

    /** Queue of users to be displayed by the random grid */
    userQueue = []

    /** Last offset into the user database */
    lastFetchedUserDocument = null

    /** Discoverable users */
    discoverableUsers = []

    /** Load content */
    async load() {

        // Check values
        //if (!this.userID) throw new Error("Missing 'user' in the URL.")
        if (!this.campaignID) throw new Error("Missing 'campaign' in the URL.")

        // Initialize SDK
        this.app = initializeApp({
            apiKey: "AIzaSyDaNOhkBoDqgTm2eNCOC_7GxaJxqGgz6gg",
            authDomain: "vatom-ball.firebaseapp.com",
            projectId: "vatom-ball",
            storageBucket: "vatom-ball.appspot.com",
            messagingSenderId: "512347948356",
            appId: "1:512347948356:web:28923a55b92456add50311",
            measurementId: "G-2KYD4EMTW1"
        })

        // Get DB
        this.firestore = getFirestore(this.app)

        // Fetch current user's document
        if (this.userID) {
            let docSnap = await getDoc(doc(this.firestore, "campaigns", this.campaignID, "users", this.userID))
            if (docSnap.exists()) {
                
                this.currentUser = docSnap.data()
        
                // Get all friends
                let friends = (await Promise.all((this.currentUser.friends || []).map(async friendID => {
                        
                    // Get user doc
                    try {
                        let friendSnap = await getDoc(doc(this.firestore, "campaigns", this.campaignID, "users", friendID))
                        if (!friendSnap.exists()) throw new Error(`User ${friendID} does not exist.`)
                        return { friend: true, ...friendSnap.data() }
                    } catch (err) {
                        console.warn(`[Database] Unable to load friend ${friendID}: ${err.message}`)
                        return null
                    }
        
                }))).filter(doc => !!doc)
        
                // Add each friend to the front of the queue of "random" users to display in the grid
                console.debug(`[Database] Loaded current user: id=${this.userID}, friends=${friends.length}`)
                for (let friend of friends)
                    this.userQueue.push(friend)
            } else {
                //throw new Error("This user does not exist.")
            }
        }
        
        // Fetch all discoverable users
        let c = collection(this.firestore, 'campaigns', this.campaignID, 'users')
        let q = query(c, where('discoverable', '==', true))
        // let p  = query(c)
        let snap = await getDocs(q)
        // let snapAll = await getDocs(p)
        this.discoverableUsers = snap.docs.map(d => d.data())
        this.allUsers = []//snapAll.docs.map(d => d.data())
        // for (let i = 0 ; i < 4 ; i++) this.discoverableUsers = this.discoverableUsers.concat(this.discoverableUsers.slice())
        console.debug(`[Database] Loaded ${this.discoverableUsers.length} discoverable users`)

    }

    /** Check if the next user returned by getNextUser() will be a friend of the current user. */
    get isNextUserAFriend() {
        return this.userQueue[0]?.friend
    }

    /** Get the next user in the queue of users to display for this user */
    async getNextUser() {

        // Get next item from the queue
        let nextItem = this.userQueue.shift()
        if (nextItem)
            return nextItem

        // No next item found! Do the next query from the database
        let fetchAmount = 100
        let randomFactor = this.hasLoopedUsers ? 0 : (this.currentUser?.randomFactor || 0)
        let userCollection = collection(this.firestore, "campaigns", this.campaignID, "users")
        let q = null
        if (this.lastFetchedUserDocument)
            q = query(userCollection, where('stage', '==', 'approved'), where("randomFactor", ">", randomFactor), orderBy("randomFactor"), startAfter(this.lastFetchedUserDocument), limit(fetchAmount))
        else
            q = query(userCollection, where('stage', '==', 'approved'), where("randomFactor", ">", randomFactor), orderBy("randomFactor"), limit(fetchAmount))
        let snapshot = await getDocs(q)

        // Warn if no users exist
        if (snapshot.size == 0 && this.hasLoopedUsers)
            console.warn(`[Database] No users found in the database!`)

        // Add users to the queue
        for (let doc of snapshot.docs)
            this.userQueue.push(doc.data())

        // Update offset
        if (snapshot.size < fetchAmount && !this.lastFetchedUserDocument) {

            // Looped around! But the user database is too small
            console.debug(`[Database] Fetched ${snapshot.size} random users, and looped back to the beginning. Duplicating users to fill up the small database...`)
            this.lastFetchedUserDocument = null
            this.hasLoopedUsers = true

            // Special case: If the database has too few items in it, this can become slow. So let's manually duplicate items
            // to fill up the queue.
            let nextIndex = 0
            while (this.userQueue.length < fetchAmount && snapshot.docs.length > 0) {
                this.userQueue.push(snapshot.docs[nextIndex].data())
                nextIndex += 1
                if (nextIndex >= snapshot.docs.length) nextIndex = 0
            }

        } else if (snapshot.size < fetchAmount) {

            // Looped around!
            console.debug(`[Database] Fetched ${snapshot.size} random users, and looped back to the beginning.`)
            this.lastFetchedUserDocument = null
            this.hasLoopedUsers = true

        } else {

            // Not looped around, just update offset
            console.debug(`[Database] Fetched ${snapshot.size} random users, the database still has more.`)
            this.lastFetchedUserDocument = snapshot.docs[snapshot.docs.length-1]

        }

        // Done, return the next item in the queue
        return this.userQueue.shift()

    }

}