/**
 * A collection of components that are shared throughout the app.
 */
export let SharedComponents = {

    /** @type {App} The main React app */
    app: null,

    /** @type {App3D} The 3D component of the app */
    app3D: null,

    /** True if this is a "high-powered" device, false if not or we didn't recognize the device */
    isHighPowered: false,

    /** True if this is an iOS device, which has many issues so we remove some features on iOS. */
    isIOS: false,

}

/** Store for debug access */
if (window.location.hostname == 'localhost')
    window.SharedComponents = SharedComponents